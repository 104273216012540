import React from 'react';
import { FacebookOutlined, Twitter, Google } from '@mui/icons-material';
const MemberCard = ({name, title, text, image}) => {
  return (
    <div className="col-md-5 mx-auto mb-5">
        <div className="card position-relative shadow h-100">
            <div className="img-top">
                <img src={image} alt="innovative team member" className="img-fluid w-100" />
            </div>
            <div className="card-body px-5  text-center">
                <h3 className="text-success">{name}</h3>
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        </div>
    </div>
  )
}

export default MemberCard;