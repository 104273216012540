import React from "react";
import { Dehaze } from "@mui/icons-material";
const Navbar = () => {
  return (
    <nav
      className={`navbar navbar-expand-md position-fixed shadow-sm py-0 w-100 bg-light`}
    >
      <div className="container py-0">
        <a to="" className="navbar-brand fs-3 fw-bolder py-0 text-green ">
          <img src="/images/Greentrack Technology Logo.png" alt="logo" width={"60px"} style={{paddingTop: '5px'}}/>
          {/* <span className='nav-name ms-2'>NewCo</span> */}
        </a>
        <label
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#mobileMenu"
          aria-controls="mobileMenu"
          aria-expanded="false"
          aria-label="navigation bar"
        >
          <Dehaze className="text-green" />
        </label>
        <div className="collapse navbar-collapse" id="mobileMenu">
          <ul className="navbar-nav ms-auto text-center text-dark px-3">
            <li className="nav-item">
              <a href="#" className="nav-link mx-3 text-dark">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a href="#features" className="nav-link mx-3 text-dark">
                Features
              </a>
            </li>
            <li className="nav-item">
              <a href="#pricing" className="nav-link mx-3 text-dark">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a href="#team" className="nav-link mx-3 text-dark">
                Team
              </a>
            </li>
            <li className="nav-item">
              <a href="#about" className="nav-link mx-3 text-dark">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
