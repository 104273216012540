import '../styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import Navbar from '../components/navbar';
import Header from '../components/header';
import Features from './features';
import Pricing from './pricing';
import Team from './team';
import Dashboard from './dashboard';
import Connected from './connected';
import CallToAction from '../components/callToAction';
import Plan from './plan';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Header/>
      <Features/>
      <Dashboard/>
      <Connected/>
      <Pricing/>
      <Team/>
      <Plan/>
      <CallToAction/>
    </div>
  );
}

export default App;
