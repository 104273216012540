import React from 'react'

const Header = () => {
  return (
    <div className=' hero mb-4'>
        <div className='text-center'>
            <h1 className='text-center'>Environmental Compliance Made <br/> Effortless, Easy, & Affordable</h1>
            <p className='text-muted mt-4'>Finally, a compliance software which actually measures regulatory health in real-time.</p>
            <a className="btn btn-success bg-green rounded-0 shadow my-3" href='https://forms.gle/PJ9TKYz5DeoqZSvw8' target="_blank">GET A QUOTE</a>
            
        <div className="mx-auto w-75">
          <img src="/images/ecotrack1.png" alt="" className="img-fluid shadow-lg" />
        </div>
        </div>
    </div>
  )
}

export default Header;