import React from 'react';
import PricingCard from '../components/pricingCard';
import Separator from '../components/separator';
const Pricing = () => {
  return (
    <div className='min-vh-100 position-relative bg-sm-green' id='pricing'>
        <div className="h-50 w-100 bg-green back d position-absolute">
        </div>
        <div className="container  py-5">
          <div className="text-center mb-5 text-light">
            <p>PRICING PLANS</p>
            <h3>THE COST OF OUR INNOVATION TO END-USERS</h3>
            <Separator/>
          </div>
          <div className="row mb-4">
              <div className="col-md-6 my-3">
                <div className="card position-relative shadow bg-green light-border h-100">
                    <div className="card-body p-0">
                        <div className='text-center text-light py-5 '>
                            <div className='p-2 '>
                                <h3 className='fw-bold'> Price of Sensors</h3>
                                <p className="text-center">
                                    The price of the sensors Greentrack will sell vary depending on the type of environment sensor itself.
                                    Below are some rough average prices of example sensors:
                                </p>
                                <div className="row py-3">
                                    <div className="col-6 ">
                                        <h5>$650 - Water Sensor</h5>
                                        <img className='img-fluid' src="/images/sensor1.png" alt="this is an image" width='150px' />
                                    </div>
                                    <div className="col-6">
                                        <h5>$150 - Noise Meter</h5>
                                        <img className='img-fluid' src="/images/sensor2.png" alt="this is an image" width='150px' />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
              </div>
              <div className="col-md-6 my-3">
                <div className="card position-relative shadow bg-green light-border h-100">
                    <div className="card-body p-0">
                        <div className='text-center text-light py-5 '>
                            <div className='p-2'>
                                <h3 className='fw-bold'> Price of Software</h3>
                                <p className="text-center">
                                The price of Greentrack's software is a base fee plus a fixed monthly
                                fee however, this fee is contingent on the number of
                                locations or facilities a company operates on account.
                                </p>
                                <div className="row">
                                    <div className="col text-center my-5 py-3">
                                        <h4>$450 - One Location</h4>
                                        <h4>$2,250 - 20 Locations</h4>
                                        <h4>$5,500 - 40 Locations</h4>
                                        
                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
    </div>
  )
}
const pricingPlan = [
  {
    plan: 'BASIC',
    price: '450',
    dark: false
  },
  {
    plan: 'SILVER',
    price: '2,250',
    dark: true
  },
  // {
  //   plan: 'GOLD',
  //   price: '5,500',
  //   dark: false
  // },
]
export default Pricing;