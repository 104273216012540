import React from 'react';

const Dashboard = () => {
  return (
    <div className="container my-5 py-5" id='supplier'>
      <div className="row">
        <div className="col-md-6 ">
          <div className=''>
            <img src="/images/icons/icon7.jpg" alt="the is a board" className="img-fluid" width={'50px'} />
          </div>
          <div className='mb-5 fs-5'>
            <h3>Powerful, Informative Central Dashboard</h3>
            <p className='text-muted'>
              Immediately view compliance health upon logging in.
              Companies can choose which environmental factors they'd like
              to see along with the type of visuals which please them best.
            </p>
          </div>
          <div className='pe-5'>
            <h5>1. Centralized Data</h5>
            <p className='text-muted'>
              Information readily available for every employee or manager in one
              convenient spot. No more miscommunication or information gaps and
              discrepancies.
            </p>
          </div>
          <div className='pe-5'>
            <h5>2. Simplified UI/UX</h5>
            <p className='text-muted'>
              Friendly, simple user experience. Easily Navigate to any area on the
              application from the main dashboard for quick access to any feature.
            </p>
          </div>
          <div className='pe-5'>
            <h5>3. Customizability</h5>
            <p className='text-muted'>
              Administrators are able to customize their dashboard to highlight
              features they care for most. Whether that's current noise levels, ambient
              dust monitoring levels, or materials logs.
            </p>
          </div>
        </div>
        <div className='col-md-6 py-auto pt-sm-5 d-flex align-items-center'>
          <img src="/images/ecoTrack2.png" alt="" className="img-fluid shadow-lg" />
        </div>
      </div>
    </div>
  )
}

export default Dashboard;