import React from 'react';
import Separator from './separator';

const CallToAction = () => {
    const bgImage = {backgroundImage: "url('./images/bg.jpg')"}
  return (
    <div className='call' style={bgImage}>
        <div className="text-light text-center content container">
            <p>ARE YOU READY</p>
            <h2>Lets start your project now</h2>
            <Separator/>
            <p>
              Whether the business in question is a manufacturing firm, a transportation company, utility provider, <br /> or anything in between, Greentrack can simplify the compliance process.
            </p>
            <a className="btn text-dark btn-light px-4 my-3 rounded-0" href='https://forms.gle/PJ9TKYz5DeoqZSvw8' target="_blank">Contact Now</a>
        </div>
    </div>
  )
}

export default CallToAction;