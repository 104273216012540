import React from 'react';

const Connected = () => {
  return (
    <div className="container py-5">
        <div className="row pb-5">
            <div className="col-md-6">
                <img src="/images/ecoTrack3.png" alt="" className="img-fluid shadow-lg" />
            </div>
            <div className="col-md-6">
                <img src="/images/icons/icon8.jpg" alt="" className="img-fluid" width={'50px'}/>
                <h2 className='mb-5'>Stay Connected, Empowered</h2>
                <p className='text-muted mb-5'>
                    Easily organize any team's compliance efforts and keep
                    everyone in the loop continuously. Every minute counts in
                    compliance and that one text automated from greentrack's
                    system can be the trigger to a cost-saving leakage inspection.
                    Create tasks, plans, and schedules easily for teams to view.
                </p>
            </div>
        </div>
        <div className="row my-5">
            <div className="col-md-6 py-5">
                <img src="/images/icons/icon9.jpg" alt="" className="img-fluid" width={'50px'}/>
                <h2 className='mb-5'>Real-Time Analytics</h2>
                <p className='text-muted mb-5'>
                    greentrack collects the data from each sensor in real-time for decision-makers to monitor but also
                    develops insights from said data to aid with
                    decision-making, uncover gaps in compliance, and
                    highlight ways to diminish those gaps.
                </p>
            </div>
            <div className="col-md-6 d-flex align-items-center">
                <img src="/images/ecoTrack4.png" alt="" className="img-fluid shadow-lg" />
            </div>
        </div>
    </div>
  )
}

export default Connected;
