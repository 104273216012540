import React from 'react';

const FeatureCard = ({img,title, body}) => {
  return (
    <div className='col-12 col-md-4 text-light text-center'>
        <img className='img-fluid' src={img} alt="" width={'75px'}/>
        <h5>{title}</h5>
        <p>{body}</p>
    </div>
  )
}

export default FeatureCard;