import React from "react";
import FeatureCard from "../components/featureCard";
import Separator from "../components/separator";

const Features = () => {
  return (
    <div className="bg-green  container-fluid py-5" id="features">
      <div className="container">
        <div className="text-center text-light">
          <h2>Features</h2>
          <p className="fs-4">
            The ways in which Greentrack Technology helps overcome regulatory hurdles
          </p>
          <Separator />
        </div>
        <div className="row">
          {featureContent.map((feature, index) => {
            return <FeatureCard key={index} {...feature} />;
          })}
        </div>
      </div>
    </div>
  );
};

const featureContent = [
  {
    img: "./images/icons/icon1.jpg",
    title: "Environmental Sensors",
    body: "Greentrack uses top-notch physical environmental sensors to actually gauge the environmental impact of operations in real-time with extreme accuracy. This data is then used in the management software. greentrack provides air quality, water quality, and noise level sensors to give companies real data.",
  },
  {
    img: "./images/icons/icon2.jpg",
    title: "Powerful Reporting & Documentation",
    body: "Greentrack offers a data repository which allows for all historical records, documents, and more to be stored nicely. Businesses can easily create reports in PDF, XLS, and other formats which match regulatory reporting standards using greentrack's premade templates.",
  },
  {
    img: "./images/icons/icon3.jpg",
    title: "Configurable Thresholds",
    body: "Empowering companies through customizable settings. Businesses can configure regulatory thresholds easily for each and every environmental category to adhere to regional legal parameters but also their own company practices.",
  },
  {
    img: "./images/icons/icon4.jpg",
    title: "Proactive Alerts",
    body: "Whenever a scheduled inspection is missed, threshold is breached, or audit is required, timely alerts will be sent out to chosen recipients to notify and empower team members before mistakes turn into costly fines and penalties.",
  },
  {
    img: "./images/icons/icon5.jpg",
    title: "Same Account, Any Device",
    body: "Phone, laptop, tablet, it doesn't matter,  users can utilize the same administrative and user accounts regardless of device type to achieve a shared, centralized interface.",
  },
  {
    img: "./images/icons/icon6.jpg",
    title: "Corrective Actions",
    body: "The ability to set up tasks which automatically get carried out by Greentrack's system once a certain trigger the company dictates is activated. Allowing companies to send notifications, turn systems off, schedule hazardous material inspections and more through corrective actions.",
  },
];

export default Features;
