import React from 'react';

const Plan = () => {
  return (
    <div className="container my-5 py-5" id='about'>
        <div className="row">
            <div className="col-md-6">
                <div className='mb-5'>
                    <h1>PLAN BUILDER</h1>
                    <p>Create a comprehensive compliance plan 
                     to organize efforts along with constant monitoring.</p>
                </div>
                <div className="row mb-3">
                    <div className="col-2">
                        <img src="/images/icons/icon10.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-10">
                        <h5>Schedules</h5>
                        <p className='text-muted'>
                        Design and activate a site inspection and audit schedule for each regulatory category.
                        </p>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-2">
                        <img src="/images/icons/icon11.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-10">
                        <h5>Preventative Program</h5>
                        <p className='text-muted'>
                            With the user-friendly interface, create maintenance procedures, facility maps, leakage prevention plans, and more effortlessly.
                        </p>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-2">
                        <img src="/images/icons/icon12.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="col-10">
                        <h5>KPIs AND BMPs</h5>
                        <p className='text-muted'>
                        Customize and define best management
                        practices and KPIs. Measure their success.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <img src="/images/img8.jpg" alt="" className="img-fluid" />
            </div>
        </div>
    </div>
  )
}

export default Plan;