import React from 'react'

const Separator = () => {
  return (
      <div className="d-flex justify-content-center pb-5">
          <div className='separator'></div>

      </div>
  )
}

export default Separator;