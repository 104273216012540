import React from "react";
import MemberCard from "../components/memberCard";
const Team = () => {
  return (
    <div className="container my-5 py-5" id="team">
      <div className="text-center mb-5">
        <p>OUR EXPERTS</p>
        <h2>Innovative team</h2>
      </div>
      <div className="row">
        {teamMembers.map((member, index) => {
          return <MemberCard key={index} {...member} />;
        })}
      </div>
    </div>
  );
};

const teamMembers = [
  {
    name: "Brian Tam",
    title: "CEO & FOUNDER",
    image: "/images/brian.jpg",
    text: "Brian Tam is an expert in the fields of environmental science and technology, having worked as an environmental officer in various high-profile companies. His vision to streamline compliance propels him forward.",
  },
  {
    name: "Arthur Lai",
    title: "CTO & CO-FOUNDER",
    image: "/images/aruthur.jpg",
    text: "Arthur has extensive experience in application engineering, software engineering and IoT product development. This along with his business acumen makes him a perfect Chief Technology Officer.",
  },
];

export default Team;
